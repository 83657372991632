import { ReactComponent as Title } from "../assets/title.svg";
// import { useTranslation } from "react-i18next";

import "./Manifesto.css";
// import { useEffect, useState } from "react";

export function Manifesto() {
  // const { t, i18n } = useTranslation();
  // const [englishClassName, setEnglishClassName] = useState("manifesto-english");

  // useEffect(() => {
  //   if (i18n.language === "en-US") {
  //     setEnglishClassName("manifesto-english");
  //   } else {
  //     setEnglishClassName("");
  //   }
  // }, [i18n.language]);

  return (
    <div className={"manifesto"}>
      <Title height="52px" className="manifesto-title" />
      <p>
        지난 10년간 다양한 곳에서 프로덕트를 만들면서 느낀 것은, 빠르게 행동하는
        것이 다른 무엇보다 중요하다는 것이었습니다. 스타트업계 사람들은 이 말을
        일종의 성배마냥 받들지만, 행동을 보면 별로 진심으로 그렇게 여기는 것
        같지는 않더군요. 이런 저런 이유를 들어서 빠르기 실행하기 꺼려합니다.
        때로는 이런 미루는 행동이, 매번 부딪히는 엔지니어의 사고방식과 기획자의
        사고방식 사이에서 유일하게 합의된 부분이 아닐까 하는 생각도 합니다.{" "}
        <i>
          아직 프로덕트를 런칭할 준비가 안 됐어. 이대로 런칭하면 사람들에게
          외면받고 말 거야.
        </i>
      </p>
      <p>
        지금까지 만들었던, 우리가 MVP(Minimum Value Product)라고 칭했던 제품들의
        기능에는 로그인, 기본적인 푸시 메시지 기능이 포함되었고, 디자인이 너무
        별로지 않아야 하며, 이 모든 걸 다 만족하고도 핵심 기능을 제공해야
        합니다. 이 모든 걸 다 최소 가치라 말 할 수 있을까요? 이 최소 기능 제품을
        만들기 위해 필요한 시간은 얼마일까요? 제 경험상 숙련된 엔지니어들로
        구성된 팀이 만든다 하더라도 한 달에서 두 달 정도는 잡았던 것으로
        기억합니다.
      </p>
      <p>
        이런 탐색 과정이 잘못된 것은 아닙니다만, 저 과정을 한번 겪는데 최소
        2개월에서 6개월이 걸립니다.{" "}
        <b>너무 느리지 않나요? 세상은 점점 더 빨라지고 있습니다.</b>
      </p>
      <p>
        굳이 제품이 나와있지 않더라도, 사람들에게 팔리는지 확인해 볼 수 있을
        겁니다. 이때 "좋은 아이디어인데?" 라는 평가만으로는 부족합니다. 실제로
        사람들에게 돈을 받아보고, 그리고 어떻게든 문제를 해결해 보는 겁니다.
        그리고 그게 더 잘 팔린다면 그 때 자동화하는 프로덕트를 만들면 되겠죠.{" "}
        <b>이걸 최소가치입증(Minimum Value Proof: MVP) 이라고 부르겠습니다.</b>
      </p>
      <p>
        빨라지는 속도만 아니라, 점차 레드오션으로 나아갈 스타트업 씬에서
        살아남기 위해서는 기존의 방식과 접근을 달리해야 합니다. 저는 위에서 말한
        사고의 전환이 다가오는 미래에 대한 좋은 해결책이라고 생각합니다.
      </p>
      <hr />
      <p>
        최소가치입증을 거치더라도 다른 문제가 있습니다. 회사가 커져감에 따라
        자연스럽게 처리해야 할 일이 늘어나며, 그에 따라 고용인의 수가 늘고
        의사결정 과정에서 고려해야 하는 사람의 수가 늘어나게 될 것입니다. 그에
        따라 <b>빠른 의사결정이 점차 힘들어지게 됩니다</b>.
      </p>
      <p>
        성공과 성장에 중요한 역할을 했던 빠른 의사결정을, 성장했기 때문에 하지
        못하는 일종의 역설적인 상황이 발생합니다. 저는 이러한 상황을{" "}
        <i>성장-실행 역설</i> 이라고 부릅니다. 사실상 거의 모든 회사가 겪어 온
        일이기 때문입니다. 하지만, 이러한 역설도 점차 해결되는 모습이 보입니다.
      </p>
      <p>
        유니스왑
        <sup id="ref-1">
          <a href="#footnote-1">[1]</a>
        </sup>
        은 유니콘
        <sup id="ref-2">
          <a href="#footnote-2">[2]</a>
        </sup>
        을 달성할 때 팀원이 20명이 채 되지 않았습니다. 노션
        <sup id="ref-3">
          <a href="#footnote-3">[3]</a>
        </sup>
        은 40명 규모에 유니콘을 달성했습니다. 이러한 회사들이 단순히 운이 좋아서
        탄생한 아웃라이어일까요? 아닐 것입니다.
      </p>
      <p>
        향후 10년을 지배할 기술을 보면 확실해집니다. 향후 10년동안 우리의 삶에
        녹아들 기술은 무엇이 있을까요? 저는 블록체인과 AI라고 생각합니다.
        표면적으로나, 기술적으로나 전혀 달라보이는 두 기술의 공통점은 바로{" "}
        <b>행동 주체의 간소화</b>를 이끌어낸다는 점입니다.
      </p>
      <p>
        블록체인에 의해 지속적 관리가 필요한 큰 규모의 사업은, 큰 규모의 회사를
        바탕으로 이루어져야 한다는 규칙이 무너졌습니다. 이러한 현상은 AI의
        등장으로 인해서 가속화 될 것입니다. 생성형 AI를 사용해 보셨나요? 적절한
        사용 방법만 이해한다면, 쉽고 빠르게 데이터를 분석하고 처리할 수
        있습니다. 이러한 기술의 발전을 생각해 보면, 위에 언급한 아웃라이어들은
        더더욱 늘어날 것이라는 결론에 도달할 수 있습니다.
      </p>
      <p>
        뿐만 아니라 이런 기술들을 사용해서 소규모 팀으로도 큰 가치를 만들어 낸
        기업은 여전히 작은 팀으로써 가지는 이점인 빠른 의사결정과 실행속도를
        가질 수 있게 됩니다. <b>성장-실행 역설</b>이 해소되고 더욱 효율적인
        회사가 될 수 있을 것입니다.
      </p>
      <p>
        그렇다면{" "}
        <b>
          수조원 기업가치를 호가하는 사업의 사무실 규모가 오피스텔 하나인 세상이
          머지 않았다고 느낍니다. 그리고 저는 여기에 미래를 베팅 해 볼
          생각입니다.
        </b>
      </p>
      <hr />
      <p>
        테이크 오프라는 서핑 용어를 들어보신 적이 있나요? 파도에 올라탄다는
        의미로, 테이크 오프에 성공하면 이제 서핑 보드 위에 일어서서, 파도가
        밀어주는 힘을 통해 앞으로 나아가게 됩니다.
      </p>
      <p>
        스타트업 씬에서 이러한 파도는 사람들의 삶에 영향을 미치는 기술의
        등장으로 일어납니다. 2008년 스마트폰의 등장으로 얼마나 많은 유니콘
        기업이 생겨났는지 생각 해 보세요. 이러한 파도 위에 올라타는 것, 즉 선점
        효과는 어마어마한 위력을 발휘합니다.
      </p>
      <p>
        새로운 패러다임을 선점 혹은 탐구해서 이득을 보는 것은 저희가 매우 잘
        하던 일이었습니다. 새로운 기술이나 세상의 변화에 관심을 갖고, 그를
        예측해서 한발짝 빨리 뛰어드는 것입니다. 저희는 늘 이렇게 선점효과를
        누려왔습니다. 그리고 저희는 아이폰 이후의 파도는 AI와 블록체인 근처에
        있고, 2025년 즈음 시작될 것이라 생각합니다. 직접 움직이면서 테이크
        오프를 위해 여러 준비를 할 시간이 다가온 것이죠.
      </p>
      <p>
        그렇기에, 이러한 파도 위에 몸을 얹어보고자, 저희는 이렇게 행동할
        것입니다.
      </p>
      <ol>
        <li>
          우리는 개발자도, 창업가도 아닌 문제를 푸는 사람들이다. <br />
          문제를 중심으로 움직일 것이다.
        </li>
        <li>빠르게 변화하는 세상 속에서는 핵심 가치가 더욱 중요해진다.</li>
        <li>
          그러므로 핵심 가치를 빠르게 찾고, 빠르게 실행하는 방식으로 움직일
          것이다.
          <sup id="ref-4">
            <a href="#footnote-4">[4]</a>
          </sup>
        </li>
        <li>
          새로운 기술들을 잘 활용하여, 기존의 규칙을 따르지 않고
          <br /> 실질적인 가치를 만들어 낼 것이다.
        </li>
      </ol>

      <p>읽어주셔서 감사합니다.</p>

      <div className="manifesto-signature">
        <p>2024.12.21</p>
        <p>프랭클린 인베스트먼트</p>
      </div>

      <hr className="manifesto-footnote-divider" />
      <div className="manifesto-footnotes">
        <h3>Footnotes</h3>

        <p id="footnote-1">
          <sup>
            <a href="#ref-1">[1]</a>
          </sup>{" "}
          이더리움 위의 DEX.{" "}
          <a href="https://app.uniswap.org/">https://app.uniswap.org/</a>
        </p>
        <p id="footnote-2">
          <sup>
            <a href="#ref-2">[2]</a>
          </sup>{" "}
          기업 가치가 1조 이상인 기업
        </p>
        <p id="footnote-3">
          <sup>
            <a href="#ref-3">[3]</a>
          </sup>{" "}
          프로젝트 관리 및 기록 소프트웨어.{" "}
          <a href="https://notion.so/">https://notion.so/</a>
        </p>
        <p id="footnote-4">
          <sup>
            <a href="#ref-4">[4]</a>
          </sup>{" "}
          샘 알트먼은 창업자가 빠르게 의사결정하고 빠르게 실행하는지 여부가
          회사의 성공을 판가름하기 가장 좋은 지표라고 언급했습니다.{" "}
          <a href="https://youtu.be/pW_sShJ3xvI?si=TBRWvOMR1nm_t6Pa&t=1026">
            영상
          </a>
        </p>
      </div>
    </div>
  );
}
