import "./App.css";
import { Manifesto } from "./components/Manifesto";
import Footer from "./components/Footer";
import { I18nextProvider } from "react-i18next";
import i18n, { resources } from "./locales/i18n";
// import LanguageButton from "./components/LanguageButton";

function App() {
  console.log(resources);
  return (
    <I18nextProvider i18n={i18n}>
      {/* <LanguageButton /> */}

      <div className="App">
        <div className="app-content">
          <Manifesto />
          <Footer />
        </div>
      </div>
    </I18nextProvider>
  );
}

export default App;
